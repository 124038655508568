import { renderSlot as _renderSlot, mergeProps as _mergeProps, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("button", _mergeProps(_ctx.$attrs, {
    type: "button",
    class: ['w-full rounded-md border border-transparent py-2 text-sm font-medium text-white shadow-sm focus:outline-none',
          _ctx.$attrs.disabled ? 'bg-app-theme-100' : 'bg-app-theme-500 hover:bg-app-theme-400']
  }), [
    _renderSlot(_ctx.$slots, "default")
  ], 16))
}