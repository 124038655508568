import axios from '@/services/axiosWrapperService';
import {ServiceResponse} from "@/interfaces/serviceResponse.interface";

const AuthRepository = {
    /* Attempts to authenticate user with login credentials. Returns token and user data on success. */
    async authenticateUser(username: string, password: string): Promise<ServiceResponse> {
        try {
            const res = await axios.post("auth/token/", {username: username, password: password});
            if (res && res.status !== 200) {
                return { response: res, error: Error(`Received http status code ${res.status}`) };
            }
            return { response: res };
        }
        catch (err: any) {
            const serviceResponse: ServiceResponse = { response: err.response, error: err };
            if (err.response.status === 401) {
                serviceResponse.displayError = "Combinatie van gebruikersnaam en wachtwoord is niet bekend";
            }
            return serviceResponse;
        }
    },
    async isAuthenticated(): Promise<ServiceResponse> {
        try {
            const res = await axios.get("auth/check/");
            if (!res) {
                console.warn("Attempted auto login but got no response.");
            }
            else if (res && res.status !== 200) {
                return { response: res, error: Error(`Received http status code ${res.status}`) };
            }
            return { response: res };
        }
        catch (err: any) {
            return { response: err.response, error: err };
        }
    }
}

export default AuthRepository;