import { resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = {
  key: 0,
  class: "w-full fixed bottom-0 mb-1"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_AlertViewerAlert = _resolveComponent("AlertViewerAlert")!

  return (_ctx.showAlert)
    ? (_openBlock(), _createElementBlock("div", _hoisted_1, [
        (_ctx.currentAlert?.type === _ctx.AlertTypes.SUCCESS)
          ? (_openBlock(), _createBlock(_component_AlertViewerAlert, {
              key: 0,
              title: "Hoera",
              message: _ctx.currentAlert?.text,
              onCloseAlert: _ctx.closeCurrentAlert,
              class: "bg-teal-100 border-teal-400 text-green-700"
            }, null, 8, ["message", "onCloseAlert"]))
          : _createCommentVNode("", true),
        (_ctx.currentAlert?.type === _ctx.AlertTypes.WARNING)
          ? (_openBlock(), _createBlock(_component_AlertViewerAlert, {
              key: 1,
              title: "Waarschuwing",
              message: _ctx.currentAlert?.text,
              onCloseAlert: _ctx.closeCurrentAlert,
              class: "bg-orange-100 border-orange-400 text-orange-700"
            }, null, 8, ["message", "onCloseAlert"]))
          : _createCommentVNode("", true),
        (_ctx.currentAlert?.type === _ctx.AlertTypes.ERROR)
          ? (_openBlock(), _createBlock(_component_AlertViewerAlert, {
              key: 2,
              title: "Fout",
              message: _ctx.currentAlert?.text,
              onCloseAlert: _ctx.closeCurrentAlert,
              class: "bg-red-100 border-red-400 text-red-700"
            }, null, 8, ["message", "onCloseAlert"]))
          : _createCommentVNode("", true)
      ]))
    : _createCommentVNode("", true)
}