export class URLService {

    public static getBackendURL() {
        return `https://${this.generateHostname("admin")}/api/`;
    }

    public static getEntitityManagementURL() {
        return `https://${this.generateHostname("admin")}/admin/`;
    }

    public static getAppURL() {
        return `https://${this.generateHostname()}/`;
    }

    private static generateHostname(injectSubdomainStr?: string) {
        let currentHostname = window.location.hostname;
        if (currentHostname.includes("localhost") || currentHostname.includes("192.168") || currentHostname.includes("10.0.0")) {
            currentHostname += ":8000";
        } else if (injectSubdomainStr) {
            const injectAtIndex = currentHostname.includes("www") ? 1 : 0;
            const parts = currentHostname.split(".");
            parts.splice(injectAtIndex, 0, injectSubdomainStr);
            currentHostname = parts.join(".");
        }
        return currentHostname;
    }

}