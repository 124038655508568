import {IAppConfig} from "@/interfaces/appConfig.interface";

export interface IUser {
    id: number
    companyName: string
    email: string
    username: string
    firstName: string
    lastName: string
    fullName: string
    role: UserRoles
    appConfig: IAppConfig
}

export enum UserRoles {
    ADMININISTRATOR = 1,
    DRIVER = 2
}