import {defineStore} from 'pinia';
import {IAlert} from "@/interfaces/alert";

export const useAlertStore = defineStore({
    id: "alertStore",
    state: () => {
        return {
            queuedAlerts: [] as IAlert[],
            currentAlert: {} as IAlert | null | undefined
        }
    },
    getters: {
        upcomingAlert(state) {
            return state.queuedAlerts[0];
        }
    },
    actions: {
        addAlert(alert: IAlert) {
            this.queuedAlerts.push(alert);
        },
        nextAlert() {
            if (this.queuedAlerts && this.queuedAlerts.length > 0) {
                this.currentAlert = this.queuedAlerts.shift();
                return true;
            }
            return false;
        },
        clearAlerts() {
            this.queuedAlerts = [];
            this.currentAlert = null;
        }
    }
});

