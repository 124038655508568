import {IUser} from '@/models/user.model';
import {defineStore} from 'pinia';
import localStorageService from "@/services/localStorageService";
import authRepository from "@/repositories/authRepository";
import camelcaseKeys from "camelcase-keys";
import {serviceResponseErrorHandler} from "@/handlers/serviceResponseErrorHandler";
import JWTService from "@/services/JWTService";
import * as Sentry from "@sentry/browser";
import {AppConfigurator} from "@/configuration/appConfigurator";

export const useAuthStore = defineStore({
    id: "authStore",
    state: () => {
        return {
            user: {} as IUser
        }
    },
    actions: {
        async authenticateUser(username: string, password: string) {
            const serviceResponse = await authRepository.authenticateUser(username, password);
            serviceResponseErrorHandler(serviceResponse);

            const data = serviceResponse.response.data;

            /* Parse JWT and store user object. */
            const parsedJWT = JWTService.parseJWT(data.access);
            this.user = camelcaseKeys(parsedJWT, { deep: true, stopPaths: ['user.app_config.theme'] }).user;

            /* Save tokens and user to local storage. */
            localStorageService.setAccessToken(data.access);
            localStorageService.setRefreshToken(data.refresh);
            localStorageService.setAuthenticatedUser(this.user);

            this.onAuthSuccess();

            return true;
        },
        /* Checks if the user has an access token, and if so, can access basic protected route. If not, the interceptor will kick in. */
        async checkAuthentication(): Promise<boolean> {
            if (localStorageService.getAccessToken() !== null) {
                const serviceResponse = await authRepository.isAuthenticated();
                if (serviceResponse.response && serviceResponse.response.status === 200) {
                    this.user = serviceResponse.response.data;
                    localStorageService.setAuthenticatedUser(this.user);
                    this.onAuthSuccess();

                    return true;
                }
            }
            this.logout();
            return false;
        },
        isAuthenticatedLocal() {
            return this.user && (this.user.username || this.user.email);
        },
        onAuthSuccess() {
            /* Capture user in logging */
            Sentry.setUser({ id: this.user.id.toString(), username: this.user.username, email: this.user.email });

            const appConfigurator = AppConfigurator.getInstance();
            appConfigurator.assignAppConfig(this.user.appConfig)
            appConfigurator.applyConfiguration();
        },
        logout() {
            localStorageService.clearTokens();
            localStorageService.clearAuthenticatedUser();

            Sentry.setUser(null);
            this.user = {} as IUser;
        }
    },
});