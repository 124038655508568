import {useAlertStore} from "@/store/alert";
import {AlertTypes} from "@/interfaces/alert";
import {ServiceResponse} from "@/interfaces/serviceResponse.interface";

export function serviceResponseErrorHandler(serviceResponse: ServiceResponse) {
    if (serviceResponse.response && serviceResponse.error) {
        if (serviceResponse.response?.status === 401 && !serviceResponse.displayError) {
            return  // Don't show general messages for authorization errors.
        }

        const alertStore = useAlertStore();
        let displayError: string;
        if (serviceResponse.displayError) {
            displayError = serviceResponse.displayError;
        }
        else if (serviceResponse.response?.data?.displayError) {
            displayError = serviceResponse.response.data.displayError;
        }
        else {
            displayError = "Er ging iets mis."
        }
        alertStore.addAlert({ type: AlertTypes.ERROR, text: displayError });
        throw serviceResponse.error;
    }
}