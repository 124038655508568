export interface IDialog {
    title: string
    body: string
    type: DialogTypes
    textConfirm?: string,
    textCancel?: string,
    onConfirm?: any,
    onCancel?: any,
    lock?: boolean,
    childComponent?: any,
    data?: any
}

export enum DialogTypes {
    OK = 1,
    CONFIRM = 2,
    NO_BUTTON = 3,
    CUSTOM = 4
}