
import {computed, defineComponent, onMounted, reactive, ref} from "vue";
import {ListBulletIcon, UserCircleIcon} from "@heroicons/vue/24/solid";
import {DocumentIcon, Square3Stack3DIcon} from "@heroicons/vue/24/outline";
import {useAuthStore} from "@/store/auth";
import {useUserStore} from "@/store/user";
import {
  ArrowLeftOnRectangleIcon,
  BuildingLibraryIcon,
  CalculatorIcon,
  ClockIcon,
  CurrencyEuroIcon,
  TruckIcon
} from "@heroicons/vue/24/outline";
import {useRoute, useRouter} from "vue-router";
import AdminCockpitNavigationListItem from "@/components/admin/admin_cockpit/AdminCockpitNavigationListItem.vue";
import {storeToRefs} from "pinia";
import {AppConfigurator} from "@/configuration/appConfigurator";
import {URLService} from "@/services/URLService";

export default defineComponent({
  name: "AdminCockpit",
  components: {
    AdminCockpitNavigationListItem,
    ArrowLeftOnRectangleIcon, UserCircleIcon
  },
  setup() {
    const authStore = useAuthStore();
    const userStore = useUserStore();
    const { user } = storeToRefs(authStore);
    const router = useRouter();
    const route = useRoute();

    const sidebarOpen = ref(true)

    const navigation = reactive([
      {
        name: "Werkzaamheden", routeName: "admin-activities-driver", icon: TruckIcon, current: computed(() => {
          return route.name === "admin-activities-driver"
        })
      },
      {
        name: "Vrachtdocumenten", routeName: "admin-documents-overview", icon: DocumentIcon,
        condition: user.value.appConfig.dynamic?.modules.documents, current: computed(() => {
          return route.name === "admin-documents-overview"
        })
      },
      {
        name: "Activiteiten", routeName: "admin-activities-overview", faIcon: "fa-solid fa-table-list",
        condition: user.value.appConfig.dynamic?.modules.activityOverview, current: computed(() => {
          return route.name === "admin-activities-overview"
        })
      },
      {
        name: "Tankstaten", routeName: "admin-refuel-activities-driver", faIcon: "fa-solid fa-gas-pump", current: computed(() => {
          return route.name === "admin-refuel-activities-driver"
        })
      },
      {
        name: "Administratie", icon: CalculatorIcon, open: false,
        children: [
          {
            name: "Overzicht", routeName: "drivers-administration-overview", icon: ListBulletIcon,
            current: computed(() => {
              return route.name === "drivers-administration-overview"
            })
          },
          {
            name: "Uren", routeName: "drivers-hours-week", icon: ClockIcon,
            current: computed(() => {
              return route.name === "drivers-hours-week"
            })
          },
          {
            name: "Verblijfskosten", routeName: "drivers-subsistence-allowance-week", icon: CurrencyEuroIcon,
            current: computed(() => {
              return route.name === "drivers-subsistence-allowance-week"
            })
          },
          {
            name: "Reiskosten", routeName: "drivers-travel-compensation-week", icon: CurrencyEuroIcon,
            current: computed(() => {
              return route.name === "drivers-travel-compensation-week"
            })
          },
          {
            name: "Gecombineerd", routeName: "drivers-combi-overview-week", icon: Square3Stack3DIcon,
            current: computed(() => {
              return route.name === "drivers-combi-overview-week"
            })
          },
        ]
      },
      {
        name: "Beheer",
        href: URLService.getEntitityManagementURL(),
        icon: BuildingLibraryIcon,
        current: false
      },
    ])

    const userNavigation = [
      {name: "Sign out", href: "#"},
    ]

    const adminLogoConfig = computed(() => {
      const appConfigurator = AppConfigurator.getInstance();
      return appConfigurator.getAppConfig().appearance.adminLogo;
    })

    function onMenuItemClick(item: any) {
      if (item.href) {
        window.open(item.href, "_blank");
      }
      else {
        router.push({name: item.routeName});
      }
    }

    function logout() {
      authStore.logout();
      router.replace({name: "login"});
    }

    onMounted(() => {
      userStore.fetchDrivers();
    })

    return {
      navigation, userNavigation, sidebarOpen, user, adminLogoConfig,
      onMenuItemClick, logout
    }
  }
})
