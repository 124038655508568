import {IUser} from "@/models/user.model";

class LocalStorageService {
    private KEY_ACCESS_TOKEN = "accessToken";
    private KEY_REFRESH_TOKEN = "refreshToken";
    private KEY_AUTHENTICATED_USER = "authenticatedUser";

    public setAccessToken(accessToken: string) {
        localStorage.setItem(this.KEY_ACCESS_TOKEN, accessToken);
    }

    public setRefreshToken(refreshToken: string) {
        localStorage.setItem(this.KEY_REFRESH_TOKEN, refreshToken);
    }

    public setAuthenticatedUser(user: IUser) {
        localStorage.setItem(this.KEY_AUTHENTICATED_USER, JSON.stringify(user));
    }

    public getAccessToken() {
        return localStorage.getItem(this.KEY_ACCESS_TOKEN);
    }

    public getRefreshToken() {
        return localStorage.getItem(this.KEY_REFRESH_TOKEN);
    }

    public getAuthenticatedUser() {
        const userString = localStorage.getItem(this.KEY_AUTHENTICATED_USER);
        if (userString) {
            return JSON.parse(userString);
        }
        return null;
    }

    public clearTokens() {
        localStorage.removeItem(this.KEY_ACCESS_TOKEN);
        localStorage.removeItem(this.KEY_REFRESH_TOKEN);
    }

    public clearAuthenticatedUser() {
        localStorage.removeItem(this.KEY_AUTHENTICATED_USER);
    }
}

export default new LocalStorageService();