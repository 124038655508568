import {createRouter, createWebHistory, RouteRecordRaw} from 'vue-router'
import {useAuthStore} from "@/store/auth"

const routes: Array<RouteRecordRaw> = [
    {
        path: '/',
        redirect: 'login'
    },
    {
        path: '/login/',
        name: 'login',
        component: () => import(/* webpackChunkName: "about" */ '../views/LoginView.vue')
    },
    {
        path: '/admin/activiteiten/chauffeur/',
        name: 'admin-activities-driver',
        component: () => import('../views/admin/activities/DriverActivitiesView.vue')
    },
    {
        path: '/admin/activiteiten/overzicht/',
        name: 'admin-activities-overview',
        component: () => import('../views/admin/activities/ActivitiesTableView.vue')
    },
    {
        path: '/admin/tankstaten/',
        name: 'admin-refuel-activities-driver',
        component: () => import('../views/admin/activities/RefuelActivitiesView.vue')
    },
    {
        path: '/admin/administratie/overzicht/',
        name: 'drivers-administration-overview',
        component: () => import('../views/admin/summaries/DriversAdministrationOverview.vue')
    },
    {
        path: '/admin/administratie/uren/',
        name: 'drivers-hours-week',
        component: () => import('../views/admin/summaries/DriversHoursWeekView.vue')
    },
    {
        path: '/admin/administratie/verblijfskostenvergoeding/',
        name: 'drivers-subsistence-allowance-week',
        component: () => import('../views/admin/summaries/DriversSubsistenceAllowanceWeekView.vue')
    },
    {
        path: '/admin/administratie/reiskostenvergoeding/',
        name: 'drivers-travel-compensation-week',
        component: () => import('../views/admin/summaries/DriversTravelCompensationWeekView.vue')
    },
    {
        path: '/admin/administratie/combi-overzicht/',
        name: 'drivers-combi-overview-week',
        component: () => import('../views/admin/summaries/DriversCombinedWeekView.vue')
    },
    {
        path: '/admin/documenten/overzicht',
        name: 'admin-documents-overview',
        component: () => import('../views/admin/documents/DocumentsView.vue')
    },
    {
        path: '/chauffeur/weekoverzicht/',
        name: 'driver-week-overview',
        component: () => import('../views/driver/DriverWeekView.vue')
    },
    {
        path: '/chauffeur/activiteiten/overzicht/',
        name: 'driver-activities-overview',
        component: () => import('../views/driver/activity/DriverActivitiesView.vue')
    },
    {
        path: '/chauffeur/activiteiten/nieuw/',
        name: 'driver-activity-new',
        component: () => import('../views/driver/activity/DriverActivityFormView.vue'),
    },
    {
        path: '/chauffeur/activiteiten/wijzigen/',
        name: 'driver-activity-edit',
        component: () => import('../views/driver/activity/DriverActivityFormView.vue'),
    },
    {
        path: '/chauffeur/checklist/nieuw/',
        name: 'driver-checklist-new',
        component: () => import('../views/driver/checklist/DriverChecklistFormView.vue'),
    },
    {
        path: '/chauffeur/documenten/:activityUuid?/',
        name: 'driver-documents-overview',
        component: () => import('../views/driver/document/DriverDocumentUploads.vue'),
    },
    {
        path: '/chauffeur/documenten/evaluatie/:uuid/',
        name: 'driver-document-evaluation',
        component: () => import('../views/driver/document/DriverDocumentEvaluation.vue'),
    }
]

const router = createRouter({
    history: createWebHistory(process.env.BASE_URL),
    routes
})

/* Redirect to login page if there is no user object. E.g. on page refresh. */
router.beforeEach((to, from, next) => {
    const authStore = useAuthStore();
    if (to.name !== "login") {
        if (!authStore.isAuthenticatedLocal()) {
            router.replace({ name: "login" });
        }
    }
    next();
})

router.afterEach((to, from, next) => {
    navigator.serviceWorker.getRegistrations()
        .then(registrationsArray => {
            if (registrationsArray.length > 0) {
                registrationsArray[0].update();
            }
        })
})

export default router
