export interface IAlert {
    text: string
    type: AlertTypes
    lifetime?: number
}

export enum AlertTypes {
    SUCCESS = 1,
    ERROR = 2,
    WARNING = 3
}