import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, mergeProps as _mergeProps, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "flex justify-center" }
const _hoisted_2 = { class: "font-bold" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_XMarkIcon = _resolveComponent("XMarkIcon")!

  return (_openBlock(), _createElementBlock("div", _mergeProps({
    class: "border-l-4 mx-1 p-4",
    role: "alert"
  }, _ctx.$attrs), [
    _createElementVNode("div", _hoisted_1, [
      _createElementVNode("div", null, [
        _createElementVNode("p", _hoisted_2, _toDisplayString(_ctx.title), 1),
        _createElementVNode("p", null, _toDisplayString(_ctx.message), 1)
      ])
    ]),
    _createElementVNode("span", null, [
      _createVNode(_component_XMarkIcon, {
        onClick: _cache[0] || (_cache[0] = ($event: any) => (_ctx.$emit('closeAlert'))),
        class: "btn-close-alert absolute w-14 h-14 top-0 right-0 px-4 py-3"
      })
    ])
  ], 16))
}