import {defineStore} from 'pinia';
import {IDialog} from "@/interfaces/dialog";

export const useDialogStore = defineStore({
    id: "useDialogStore",
    state: () => {
        return {
            currentDialog: null as IDialog | null
        }
    }
});

