import {defineStore} from 'pinia';

export const useAppStore = defineStore({
    id: "appStore",
    state: () => {
        return {
            packageVersion: process.env.VUE_APP_VERSION as string,
            cursorX: 0 as number,
            cursorY: 0 as number
        }
    }
});