import axios from '@/services/axiosWrapperService';
import {ServiceResponse} from "@/interfaces/serviceResponse.interface";

const UserRepository = {
    async getUsers(params: object): Promise<ServiceResponse> {
        try {
            const res = await axios.get(`/admin/users/`, { params });
            if (res && res.status !== 200) {
                return { response: res, error: Error(`Received http status code ${res.status}`) }
            }
            return { response: res };
        }
        catch (err: any) {
            return { response: err.response, error: err }
        }
    }
}

export default UserRepository;