
import {defineComponent} from "vue";
import {XMarkIcon} from "@heroicons/vue/24/solid";

export default defineComponent({
  name: "AlertViewerAlert",
  props: {
    title: {
      type: String,
      required: true
    },
    message: {
      type: String,
      required: true
    }
  },
  components: {
    XMarkIcon
  }
})
