import {IAppConfig} from "@/interfaces/appConfig.interface";

export class AppThemeConfigurator {

    public applyTheme(appConfig?: IAppConfig) {
        if (appConfig?.theme) {
            for (const [key, value] of Object.entries(appConfig.theme)) {
                document.documentElement.style
                    .setProperty(key, value)
            }
            // Change top bar color.
            document.querySelector("meta[name='theme-color']")!.setAttribute("content", appConfig.theme["--extend-colors-app-theme-500"]);
        }
    }

}