import {defineStore} from 'pinia';
import {IUser, UserRoles} from "@/models/user.model";
import UserRepository from "@/repositories/userRepository";
import {serviceResponseErrorHandler} from "@/handlers/serviceResponseErrorHandler";

export const useUserStore = defineStore({
    id: "userStore",
    state: () => {
        return {
            drivers: [] as IUser[] | null,
            selectedUser: {} as IUser | null
        }
    },
    getters: {
        driverIds(state) {
            if (state.drivers) {
                const driverIds = [];
                for (let i = 0; i < state.drivers.length; i++) {
                    driverIds.push(state.drivers[i].id);
                }
                return driverIds;
            }
            return null;
        }
    },
    actions: {
        async fetchDrivers() {
            const serviceResponse = await UserRepository.getUsers({ "filter-role": UserRoles.DRIVER });
            serviceResponseErrorHandler(serviceResponse);
            this.drivers = serviceResponse.response.data;
        }
    },
});