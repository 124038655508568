import {IAppConfig} from "@/interfaces/appConfig.interface";

const defaultAppConfig: IAppConfig = {
  "theme": {
    "--extend-colors-app-theme-50": "#eff6ff",
    "--extend-colors-app-theme-100": "#dbeafe",
    "--extend-colors-app-theme-200": "#bfdbfe",
    "--extend-colors-app-theme-300": "#93c5fd",
    "--extend-colors-app-theme-400": "#60a5fa",
    "--extend-colors-app-theme-500": "#3b82f6",
    "--extend-colors-app-theme-600": "#2563eb",
    "--extend-colors-app-theme-700": "#1d4ed8",
    "--extend-colors-app-theme-800": "#1e40af",
    "--extend-colors-app-theme-900": "#1e3a8a"
  },
  "appearance": {
    "adminLogo": {
      "filename": "logo_ritstapp.png",
      "addClass": "m-2 h-16",
      "addParentClass": ""
    }
  },
  "options": {
    "checklist": {
      "fields": []
    }
  },
  "fields": {}
}

export default defaultAppConfig;
