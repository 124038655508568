import {createApp} from 'vue'
import App from './App.vue'
import router from './router'
import {createPinia} from 'pinia'
import '/src/assets/css/tailwind.css'
import {loadFonts} from './plugins/webfontloader'
import * as Sentry from "@sentry/vue";
import {BrowserTracing} from "@sentry/tracing";
import "./registerServiceWorker";


/* --- FONT-AWESOME -----> */
import {library} from '@fortawesome/fontawesome-svg-core'
import {FontAwesomeIcon} from '@fortawesome/vue-fontawesome'

import {faEye, faGasPump, faRotateRight, faTableList, faFileExcel, faFilePdf} from '@fortawesome/free-solid-svg-icons'


library.add(faGasPump, faTableList, faRotateRight, faEye, faFileExcel, faFilePdf);
/* <----- FONT-AWESOME --- */


loadFonts()

const app = createApp(App);

Sentry.init({
    app,
    dsn: "https://3ec49081dcd7423cbd1ec7bac651baba@o4504156663250944.ingest.sentry.io/4504156676685824",
    integrations: [
        new BrowserTracing({
            routingInstrumentation: Sentry.vueRouterInstrumentation(router),
            tracingOrigins: [],
        }),
        new Sentry.Replay()
    ],
    environment: process.env.NODE_ENV,
    logErrors: true,
    tracesSampleRate: 0.2,
    replaysOnErrorSampleRate: 1.0
})

app.use(router)
    .component("FontAwesomeIcon", FontAwesomeIcon)
    .use(createPinia())
    .mount('#app')
